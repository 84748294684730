import Vue from "vue";

export const getURL = (context: any, url: string) => {
  return `${context.state.config.baseURL}${url}`;
};

export const getAuthHeaders = () => {
  return {
    headers: {
      Authorization: `Bearer ${Vue.prototype.$keycloak.token}`
    }
  };
};

export const convertToDropDownData = (source: any) => {
  return (source || []).map((c: any) => {
    return { value: c.id.toString(), text: c.name, obj: c };
  });
};

export const convertUnitToDropDownData = (source: any) => {
  return (source || []).map((c: any) => {
    return { value: c.id, text: c.title };
  });
};

export const convertCoordinatorsToDropDownData = (source: any) => {
  return (source || []).map((c: any) => {
    return { value: c.id, text: c.first_name + " " + c.last_name };
  });
};
