<template>
  <div class="b-form-textarea">
    <b-form-textarea
      :placeholder="placeholder"
      v-model="text"
      :rows="rows"
      @input="onUpdate"
      @change="onChange"
      @keypress="(e) => (remainingCount <= 0 ? e.preventDefault() : null)"
    ></b-form-textarea>
    <span v-if="hasLimit"
      >{{ limitMessage }} ({{ remainingCount }}/{{ limit }})</span
    >
  </div>
</template>

<script>
export default {
  props: {
    rows: String,
    placeholder: {
      type: String,
      default: "Enter something..."
    },
    limit: {
      type: Number,
      default: 160
    },
    hasLimit: {
      type: Boolean,
      default: false
    },
    value: String,
    limitMessage: String,
    hasError: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      text: this.value
    };
  },
  watch: {
    value: function (val) {
      this.text = val;
    }
  },
  mounted() {
    this.text = this.value;
  },
  computed: {
    remainingCount() {
      let textLen = this.text ? this.text.length : 0;
      return this.limit - textLen;
    }
  },
  methods: {
    onUpdate() {
      this.$emit("input", this.text);
    },
    onChange(e) {
      let val = e.target.value;
      if (this.hasLimit && val.length > this.limit)
        this.text = val.substr(0, this.limit);
    }
  }
};
</script>

<style lang="scss" scoped>
.b-form-textarea textarea {
  background: #fafafa;
  border: 1px solid #b0b6c2;
  box-sizing: border-box;
  border-radius: 4px 4px 0px 0px;

  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: #495057;
}
textarea:focus {
  color: #495057 !important;
}

textarea::placeholder {
  color: #717171 !important;
}

textarea:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #717171 !important;
}

textarea::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #717171 !important;
}
</style>
