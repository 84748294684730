import Vue from "vue";
import Vuex, { StoreOptions } from "vuex";
import {
  ConfigObject,
  CourseObject,
  Locations,
  Countries,
  Facilities,
  Units,
  Rooms,
  ResponseData,
  RootState
} from "@/types";

import axios, { AxiosResponse } from "axios";
import {
  convertCoordinatorsToDropDownData,
  convertToDropDownData,
  convertUnitToDropDownData,
  getAuthHeaders,
  getURL
} from "./util";

Vue.use(Vuex);

// TODO separate them to modules to accomadate typescript
const store: StoreOptions<RootState> = {
  state: {
    config: {
      SSO: "",
      baseURL: "",
      studioBaseURL: "",
      termsAndConditionsUrl: ""
    },
    userInfo: {
      email: "",
      first_name: "",
      last_name: "string",
      id: 0,
      is_instructor: false,
      is_coordinator: false
    },
    userProfile: {
      email: "",
      emailVerified: false,
      firstName: "",
      lastName: "",
      username: "",
      attributes: {
        locale: []
      }
    },
    classes: [],
    learners: [],
    filteredClasses: [],
    country: [],
    locations: [],
    facilities: [],
    coordinators: [],
    rooms: [],
    modules: [],
    chapters: [],
    units: [],
    instructor: [],
    course: {
      org: "",
      title: "",
      id: "",
      course_number: ""
    },
    registeredUser: [],
    sessionAttendance: [],
    class: null,
    error: "",
    notification: {
      message: "",
      display: false
    },
    loadingAllClasses: false,
    loadingClasses: false,
    loadingClassSessionsId: -1,
    loadingInstructor: false,
    loadingAllLearners: false,
    loadingAllClassSession: false,
    ltiUnits: [],
    enrolledUsers: []
  },
  mutations: {
    SET_CONFIG(state, config: ConfigObject) {
      state.config = config;
    },
    SET_LIST_CLASSES(state, classes: Array<JSON>) {
      state.classes = classes;
    },
    SET_LIST_LEARNERS(state, learners: Array<JSON>) {
      state.learners = learners;
    },
    SET_LIST_FILTERED_CLASSES(state, classes: Array<JSON>) {
      state.filteredClasses = classes;
    },
    TOGGLE_LIST_FILTERED_ALL_CLASSES_STATE(state, toggleState) {
      state.loadingAllClasses = toggleState;
    },
    TOGGLE_LIST_FILTERED_CLASSES_STATE(state, toggleState) {
      state.loadingClasses = toggleState;
    },
    TOGGLE_SESSIONS_STATE(state, class_id) {
      state.loadingClassSessionsId = class_id;
    },
    TOGGLE_AVAILABLE_INSTRUCTOR_STATE(state, toggleState) {
      state.loadingInstructor = toggleState;
    },
    TOGGLE_LEARNERS_STATE(state, toggleState) {
      state.loadingAllLearners = toggleState;
    },
    TOGGLE_COORDINATOR_STATE(state, toggleState) {
      state.loadingAllClassSession = toggleState;
    },
    SET_USER_INFO(state, userInfo) {
      state.userInfo = userInfo;
    },
    SET_CLASS_SESSIONS(state, { class_id, sessions }) {
      state.filteredClasses = (state.filteredClasses || []).map((c: any) => {
        return c.id == class_id ? { ...c, sessions } : c;
      });
    },
    SET_CLASS_DETAIL(state, classObj) {
      state.class = classObj;
    },
    SET_COURSE_DETAIL(state, course: CourseObject) {
      const splitIds = course.id.split("+");
      course.course_number = splitIds[1];
      state.course = course;
    },
    SET_COUNTRIES_DETAIL(state, country: Array<Countries>) {
      state.country = convertToDropDownData(country);
    },

    SET_LOCATIONS_DETAIL(state, locations: Array<Locations>) {
      state.locations = convertToDropDownData(locations);
    },

    SET_FACILITIES_DETAIL(state, facilities: Array<Facilities>) {
      state.facilities = convertToDropDownData(facilities);
    },

    SET_ROOMS_DETAIL(state, rooms: Array<Rooms>) {
      state.rooms = convertToDropDownData(rooms);
    },
    SET_ERRORS(state, error) {
      state.error = error;
    },
    SET_NOTIFICATION(state, notification) {
      state.notification = notification;
    },

    SET_MODULES_DETAILS(state, modules) {
      state.modules = (modules.sections || []).map((c: any) => {
        const item = { value: c.id, text: c.title, chapters: [] };
        item.chapters = (c.sequences || []).map((s: any) => {
          return { value: s.id, text: s.title };
        });
        return item;
      });
    },

    SET_CHAPTERS_DETAILS(state, moduleId) {
      state.chapters =
        (state.modules.find((m) => m.value == moduleId) || {}).chapters || [];
    },

    SET_UNIT_DETAILS(state, units: Array<Units>) {
      state.units = convertUnitToDropDownData(units);
    },

    SET_COORDINATOR_DETAILS(state, coordinators) {
      state.coordinators = convertCoordinatorsToDropDownData(coordinators);
    },

    SET_INSTRUCTOR_DETAIL(state, instructor) {
      state.instructor = convertCoordinatorsToDropDownData(instructor);
    },

    SET_REGISTEREDUSER_DETAIL(state, registeredUser) {
      state.registeredUser = registeredUser;
    },
    SET_SESSION_DETAILS(state, sessionAttendance) {
      state.sessionAttendance = sessionAttendance;
    },
    SET_KEYCLOAK_USER_PROFILE(state, userProfile) {
      state.userProfile = userProfile;
    },
    SET_ALL_LTI_UNITS(state, units) {
      units.forEach((unit) => {
        unit.exportStatus = "default";
      });
      state.ltiUnits = units;
    },
    SET_ENROLLED_USERS(state, enrolledUsers) {
      enrolledUsers.results.forEach((user) => {
        user.isRowHovered = false;
      });
      state.enrolledUsers = enrolledUsers;
    }
  },
  actions: {
    listAllClasses(context, { course_id, isSilentLoad }) {
      !isSilentLoad &&
        context.commit("TOGGLE_LIST_FILTERED_ALL_CLASSES_STATE", true);
      return axios
        .get(getURL(context, `/classes/${course_id}`), getAuthHeaders())
        .then((response: ResponseData) => {
          context.commit("TOGGLE_LIST_FILTERED_ALL_CLASSES_STATE", false);
          context.commit("SET_LIST_CLASSES", response.data);
        })
        .catch((err) => {
          context.commit("TOGGLE_LIST_FILTERED_ALL_CLASSES_STATE", false);
          context.commit("SET_ERRORS", err.response.data);
        });
    },
    listAllLearners(context, course_id) {
      return axios
        .get(
          getURL(context, `/courses/${course_id}/users/enrolled`),
          getAuthHeaders()
        )
        .then((response: ResponseData) => {
          context.commit("SET_LIST_LEARNERS", response.data.data);
        })
        .catch((err) => {
          context.commit("SET_ERRORS", err.response.data);
        });
    },
    listFilteredClasses(context, { course_id, status }) {
      context.commit("TOGGLE_LIST_FILTERED_CLASSES_STATE", true);
      let url = `/classes/${course_id}`;
      if (status) {
        url = `${url}?status=${status}`;
      }
      return axios
        .get(getURL(context, url), getAuthHeaders())
        .then((response: ResponseData) => {
          context.commit("TOGGLE_LIST_FILTERED_CLASSES_STATE", false);
          context.commit("SET_LIST_FILTERED_CLASSES", response.data);
        })
        .catch((err) => {
          context.commit("TOGGLE_LIST_FILTERED_CLASSES_STATE", false);
          context.commit("SET_ERRORS", err.response.data);
        });
    },
    listFilteredClassesForInstuctor(context, { course_id, status }) {
      context.commit("TOGGLE_LIST_FILTERED_CLASSES_STATE", true);
      let url = `/classes/${course_id}/attendance_summary`;
      if (status) {
        url = `${url}?status=${status}`;
      }
      return axios
        .get(getURL(context, url), getAuthHeaders())
        .then((response: ResponseData) => {
          context.commit("TOGGLE_LIST_FILTERED_CLASSES_STATE", false);
          context.commit("SET_LIST_FILTERED_CLASSES", response.data);
        })
        .catch((err) => {
          context.commit("TOGGLE_LIST_FILTERED_CLASSES_STATE", false);
          context.commit("SET_ERRORS", err.response.data);
        });
    },
    getCourseDetails(context, course_id: string) {
      const coursePromise = axios.get(
        getURL(context, `/courses/${course_id}/`),
        getAuthHeaders()
      );
      coursePromise.then((response: ResponseData) => {
        context.commit("SET_COURSE_DETAIL", response.data.data);
      });
      return coursePromise;
    },
    getUserRoleByCourseId(context, course_id: string) {
      axios
        .get(
          getURL(context, `/classes/${course_id}/myaccount`),
          getAuthHeaders()
        )
        .then((response: ResponseData) => {
          context.commit("SET_USER_INFO", response?.data);
        })
        .catch(() => {
          //  TODO: redirect to error screen.
          // context.commit("SET_USER_ROLE", true);
        });
    },
    getClassDetails(context, { courseId, classId }) {
      const classPromise = axios.get(
        getURL(context, `/classes/${courseId}/${classId}`),
        getAuthHeaders()
      );
      classPromise.then((response: ResponseData) => {
        context.commit("SET_CLASS_DETAIL", response.data);
      });
      return classPromise;
    },
    getSessionsForClass(context, { course_id, class_id }) {
      context.commit("TOGGLE_COORDINATOR_STATE", true);
      context.commit("TOGGLE_SESSIONS_STATE", class_id);
      const url = `/classes/${course_id}/${class_id}/sessions`;
      const sessionsPromise = axios.get(getURL(context, url), getAuthHeaders());
      sessionsPromise
        .then((response: ResponseData) => {
          context.commit("TOGGLE_COORDINATOR_STATE", false);
          context.commit("SET_CLASS_SESSIONS", {
            class_id,
            sessions: response.data || []
          });
          context.commit("TOGGLE_SESSIONS_STATE", -1);
        })
        .catch((err) => {
          context.commit("SET_ERRORS", err.response.data);
          context.commit("TOGGLE_SESSIONS_STATE", -1);
        });
      return sessionsPromise;
    },
    getCountryDetails(context) {
      axios
        .get(getURL(context, `/facilities/countries`), getAuthHeaders())
        .then((response: ResponseData) => {
          context.commit("SET_COUNTRIES_DETAIL", response.data);
        });
    },

    getLocationDetails(context, countryId) {
      axios
        .get(
          getURL(context, `/facilities/locations?country=${countryId}`),
          getAuthHeaders()
        )
        .then((response: ResponseData) => {
          context.commit("SET_LOCATIONS_DETAIL", response.data);
        });
    },
    getFacilitiesDetails(context, locationId) {
      axios
        .get(
          getURL(context, `/facilities/facilities?location=${locationId}`),
          getAuthHeaders()
        )
        .then((response: ResponseData) => {
          context.commit("SET_FACILITIES_DETAIL", response.data);
        });
    },
    getRoomsDetails(context, facilityId) {
      const roomPromise = axios.get(
        getURL(context, `/facilities/rooms?facility=${facilityId}`),
        getAuthHeaders()
      );

      roomPromise.then((response: ResponseData) => {
        context.commit("SET_ROOMS_DETAIL", response.data);
      });
      return roomPromise;
    },

    addClassDetails(context, { courseId, classObj }) {
      const url = getURL(context, `/classes/${courseId}`);
      return axios.post(url, classObj, getAuthHeaders());
    },

    updateClassDetails(context, { courseId, classObj, classId }) {
      const url = getURL(context, `/classes/${courseId}/${classId}`);
      return axios.put(url, classObj, getAuthHeaders());
    },

    deleteClass(context, { courseId, classId }) {
      return axios.delete(
        getURL(context, `/classes/${courseId}/${classId}`),
        getAuthHeaders()
      );
    },
    showNotification(context, notification) {
      context.commit("SET_NOTIFICATION", { ...notification, display: true });
      setTimeout(() => {
        // hide notification automatically after 20 seconds
        context.commit("SET_NOTIFICATION", { message: "", display: false });
      }, 20000);
    },
    hideNotification(context) {
      context.commit("SET_NOTIFICATION", { message: "", display: false });
    },

    getModules(context, courseId) {
      axios
        .get(
          getURL(context, `/courses/structure/${courseId}`),
          getAuthHeaders()
        )
        .then((response: ResponseData) => {
          context.commit("SET_MODULES_DETAILS", response.data.data);
        });
    },

    getChapters(context, moduleId) {
      context.commit("SET_CHAPTERS_DETAILS", moduleId);
    },

    getUnits(context, chapterId) {
      axios
        .get(
          getURL(context, `/courses/structure/unit/${chapterId}`),
          getAuthHeaders()
        )
        .then((response: ResponseData) => {
          context.commit("SET_UNIT_DETAILS", response.data.data);
        });
    },

    getCoordinators(context, courseId) {
      axios
        .get(getURL(context, `/classes/${courseId}/members`), getAuthHeaders())
        .then((response: ResponseData) => {
          context.commit(
            "SET_COORDINATOR_DETAILS",
            (response.data || {}).coordinator || []
          );
        });
    },

    cancelClass(context, { courseId, classId, reason }) {
      const url = getURL(context, `/classes/${courseId}/${classId}/cancel`);
      return axios.post(url, reason, getAuthHeaders());
    },

    getInstructor(context, { course_id, date, start_time, end_time }) {
      context.commit("TOGGLE_AVAILABLE_INSTRUCTOR_STATE", true);
      axios
        .get(
          getURL(
            context,
            `/classes/get_instructor_schedule/${course_id}/check_availability?date=${date}&start_time=${start_time}&end_time=${end_time}`
          ),
          getAuthHeaders()
        )
        .then((response: ResponseData) => {
          context.commit("TOGGLE_AVAILABLE_INSTRUCTOR_STATE", false);
          context.commit(
            "SET_INSTRUCTOR_DETAIL",
            (response.data || {}).instructor || []
          );
        });
    },

    addSession(context, { courseId, classId, session }) {
      const url = getURL(context, `/classes/${courseId}/${classId}/sessions`);
      return axios.post(url, session, getAuthHeaders());
    },

    updateSession(context, { courseId, classId, sessionId, session }) {
      const url = getURL(
        context,
        `/classes/${courseId}/${classId}/sessions/${sessionId}`
      );
      return axios.put(url, session, getAuthHeaders());
    },

    deleteSession(context, { courseId, classId, sessionId }) {
      const url = getURL(
        context,
        `/classes/${courseId}/${classId}/sessions/${sessionId}`
      );
      return axios.delete(url, getAuthHeaders());
    },

    getSessionDetails(context, { courseId, classId, sessionId }) {
      const url = getURL(
        context,
        `/classes/${courseId}/${classId}/sessions/${sessionId}`
      );
      return axios.get(url, getAuthHeaders());
    },

    addEnrolledUser(context, { course_id, class_id, payload }) {
      const url = getURL(
        context,
        `/classes/${course_id}/${class_id}/users-registered`
      );
      return axios.post(url, payload, getAuthHeaders());
    },

    getRegisteredUser(context, { course_id, class_id }) {
      context.commit("TOGGLE_LEARNERS_STATE", true);
      axios
        .get(
          getURL(context, `/classes/${course_id}/${class_id}/users-registered`),
          getAuthHeaders()
        )
        .then((response: ResponseData) => {
          context.commit("TOGGLE_LEARNERS_STATE", false);
          context.commit("SET_REGISTEREDUSER_DETAIL", response.data);
        });
    },

    addMarkAttendance(context, { course_id, class_id, session_id, payload }) {
      const url = getURL(
        context,
        `/classes/${course_id}/${class_id}/sessions/${session_id}/mark_attendance`
      );
      return axios.post(url, payload, getAuthHeaders());
    },
    cancelUserRegistration(
      context,
      { course_id, class_id, session_id, reason }
    ) {
      const url = getURL(
        context,
        `/classes/${course_id}/${class_id}/users-registered/${session_id}/cancel`
      );
      return axios.post(url, reason, getAuthHeaders());
    },
    bulkCancelRegistration(context, { course_id, class_id, payload }) {
      const url = getURL(
        context,
        `/classes/${course_id}/${class_id}/users-registered/bulk_cancel`
      );
      return axios.post(url, payload, getAuthHeaders());
    },

    getSessionAttendance(context, { course_id, class_id, session_id }) {
      context.commit("TOGGLE_LIST_FILTERED_CLASSES_STATE", true);
      axios
        .get(
          getURL(
            context,
            `/classes/${course_id}/${class_id}/sessions/${session_id}/get_attendance`
          ),
          getAuthHeaders()
        )
        .then((response: ResponseData) => {
          context.commit("TOGGLE_LIST_FILTERED_CLASSES_STATE", false);
          context.commit("SET_SESSION_DETAILS", response.data);
        })
        .catch(() => {
          context.commit("TOGGLE_LIST_FILTERED_CLASSES_STATE", false);
          context.commit("SET_SESSION_DETAILS", []);
        });
    },
    updateMarkAttendance(
      context,
      { course_id, class_id, session_id, payload }
    ) {
      const url = getURL(
        context,
        `/classes/${course_id}/${class_id}/${session_id}/mark_attendance`
      );
      return axios.patch(url, payload, getAuthHeaders());
    },
    // Virtual Classes
    listAllLtiUnits(context, { courseId }) {
      const url = getURL(
        context,
        `/course_api/v1/blocks/?course_id=${courseId}`
      );
      return axios
        .get(url, getAuthHeaders())
        .then((res) => {
          context.commit("SET_ALL_LTI_UNITS", res.data);
        })
        .catch((err) => {
          context.commit("SET_ERRORS", err.response);
        });
    },
    listEnrolledUsers(context, { courseId, blockId, search, pageSize, page }) {
      const url = getURL(
        context,
        `/enrollment/v1/courses/${courseId}?block_id=${blockId}&search=${search}&page_size=${pageSize}&page=${page}`
      );
      return axios
        .get(url, getAuthHeaders())
        .then((res) => {
          context.commit("SET_ENROLLED_USERS", res.data);
        })
        .catch((err) => {
          context.commit("SET_ERRORS", err.response);
        });
    },
    markVirtualAttendance(context, { courseId, blockId, payload }) {
      const url = getURL(
        context,
        `/course_api/courses/${courseId}/blocks/${blockId}/zoom-attendance/`
      );
      return axios.post(url, payload, getAuthHeaders()).catch((err) => {
        context.commit("SET_ERRORS", err.response.data);
      });
    },
    nextPreviousCall(context, { url, mutationName }) {
      return axios
        .get(url, getAuthHeaders())
        .then((res) => {
          context.commit(mutationName, res.data);
        })
        .catch((err) => {
          context.commit("SET_ERRORS", err.response.data);
        });
    },
    async exportEnrollments(context, { courseId, blockId }) {
      const url = getURL(
        context,
        `/enrollment/v1/courses/${courseId}/export?block_id=${blockId}
        `
      );
      try {
        const response: AxiosResponse<BlobPart> = await axios.get(url, {
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${Vue.prototype.$keycloak.token}`
          }
        });
        const blob = new Blob([response.data], { type: "text/csv" });
        // Create a link element to trigger the download
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        let filename;
        if (response.headers["content-disposition"]) {
          filename =
            response.headers["content-disposition"].split("filename=")[1];
        } else {
          filename = "zoom-attendance-export.csv";
        }
        link.download = filename;

        link.click();
        window.URL.revokeObjectURL(link.href);
      } catch (error: any) {
        context.commit("SET_ERRORS", error.response.data);
      }
    }
  },
  getters: {
    allConfig: (state) => state.config,
    getAllClasses: (state) => state.classes,
    getAllLearners: (state) => state.learners,
    getFilteredClasses: (state) => state.filteredClasses,
    getLoadingAllClasses: (state) => state.loadingAllClasses,
    isLoadingClasses: (state) => state.loadingClasses,
    loadingClassSessionsId: (state) => state.loadingClassSessionsId,
    getClass: (state) => state.class,
    getCourseDetail: (state) => state.course,
    getAllCountry: (state) => state.country,
    getAllLocation: (state) => state.locations,
    getAllFacility: (state) => state.facilities,
    getAllRooms: (state) => state.rooms,
    getAllCoordinators: (state) => state.coordinators,
    getNotification: (state) => state.notification,
    getAllModules: (state) => state.modules,
    getAllChapters: (state) => state.chapters,
    getAllUnits: (state) => state.units,
    getInstructor: (state) => state.instructor,
    getLoadingInstructor: (state) => state.loadingInstructor,
    getAllRegisteredUser: (state) => state.registeredUser,
    getLoadingAllLearners: (state) => state.loadingAllLearners,
    getLoadingAllClassSession: (state) => state.loadingAllClassSession,
    getAllSessionAttendance: (state) => state.sessionAttendance,
    getUserInfo: (state) => state.userInfo,
    getUserProfile: (state) => state.userProfile,
    getAllLtiUnits: (state) => state.ltiUnits,
    getEnrolledUsers: (state) => state.enrolledUsers,
    error: (state) => state.error
  }
};
export default new Vuex.Store<RootState>(store);
