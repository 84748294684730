<template>
  <b-modal v-model="display" hide-footer hide-header centered>
    <div :class="type" class="modal-content">
      <div class="title">
        <div>
          <img
            v-if="type == 'danger'"
            src="@/assets/wrappers/inclination.svg"
          />
          {{ title }}
        </div>
        <img src="@/assets/wrappers/cross.svg" @click="hide(false)" />
      </div>
      <div class="body">{{ body }}</div>
      <div class="buttons">
        <div class="cancel" v-if="type == 'warning'" @click="hide(false)">
          Dismiss
        </div>
        <f2f-button
          :variant="type"
          text="Yes"
          v-if="type == 'warning'"
          @click="hide(true)"
        />
        <f2f-button
          :variant="type"
          text="Dismiss"
          v-if="type == 'danger'"
          @click="hide(false)"
        />
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    display: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ""
    },
    body: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: "warning",
      validator: (value) => value == "warning" || value == "danger"
    }
  },
  methods: {
    hide(userAction) {
      this.$emit("hide", userAction);
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .modal-body {
  padding: 0px;
}
::v-deep .btn-warning {
  color: white;
}
.modal-content {
  padding: 1.5rem;
  border: none;
  .title {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.2px;
    color: #25282b;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    img {
      height: 16px;
      cursor: pointer;
      margin-right: 1rem;
    }
  }
  .body {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #757575;
    margin-bottom: 40px;
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    color: white;

    .cancel {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 16px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #a0a4a8;
      margin-right: 20px;
      cursor: pointer;
    }
  }

  .warning {
    border-left: 5px solid #ffaa15;
  }
  .danger {
    border-left: 5px solid #ff4040;
  }
}
</style>
