import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import store from "../store/index";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/course/:courseId",
    component: () => import("../views/VirtualClasses.vue"),
    children: [
      {
        path: "management",
        name: "Management",
        component: () => import("@/components/VirtualClasses/Management.vue"),
        alias: ["", "/:courseId"]
      },
      {
        path: ":blockId/attendance",
        name: "AttendanceRecord",
        component: () =>
          import("@/components/VirtualClasses/AttendanceRecord.vue")
      }
    ]
  },
  {
    path: "/:id/classes",
    name: "Classes",
    component: () => import("../views/Classes.vue"),
    children: [
      {
        path: "schedule",
        name: "schedule",
        component: () => import("../views/classes/Schedule.vue")
      },
      {
        path: "management",
        name: "management",
        component: () => import("../views/classes/Management.vue")
      },
      {
        path: "add",
        name: "add",
        component: () => import("../views/classes/Add.vue")
      },
      {
        path: "detail/:classId",
        name: "detail",
        component: () => import("../views/classes/Class.vue")
      },
      {
        path: "enrollement/:classId",
        name: "enrollement",
        component: () => import("../views/classes/EnrolledUsers.vue")
      },
      {
        path: "attendance/:classId/:session_id",
        name: "attendance",
        component: () => import("../views/classes/Attendance.vue")
      },
      {
        path: "attendancerecord",
        name: "attendancerecord",
        component: () => import("../views/classes/AttendanceRecord.vue")
      }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

// As per design suggestion redirecting user to the CMS Url
router.beforeEach((to, from, next) => {
  if (to.fullPath === "/") {
    setTimeout(() => {
      window.location.href = store.state.config.studioBaseURL;
    }, 0);
  } else {
    next();
  }
});

export default router;
