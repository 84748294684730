<template>
  <b-form-select
    @change="onUpdate"
    v-model="selected"
    :options="items"
    class="b-form-select"
  ></b-form-select>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      default: () => []
    },
    value: {
      type: [Number, String]
    },
    placeholder: String
  },
  data() {
    return {
      selected: null
    };
  },
  watch: {
    value: function (val) {
      this.selected = val;
    }
  },
  mounted() {
    this.selected = this.value;
  },
  computed: {
    items() {
      return this.placeholder
        ? [
            { value: null, text: this.placeholder, disabled: true },
            ...this.options
          ]
        : this.options;
    }
  },
  methods: {
    onUpdate() {
      this.$emit("input", this.selected);
    }
  }
};
</script>

<style scoped>
select.b-form-select {
  font-size: 17px;
  /* height: 53px;
  width: 476px;
  left: 527px;
  top: 718px; */
  background-color: #fafafa;
  box-sizing: border-box;
  border-radius: 4px 4px 0px 0px;
}
</style>
