import * as moment from "moment";

export default {
  methods: {
    formatDate: function (date) {
      return moment(date).format("YYYY-MM-DD");
    },
    returnEmptyOrName: function (source) {
      return source ? source.name : "";
    },
    formatDateByPattern: function (value, pattern) {
      return moment(value).format(pattern);
    },
    parseAndFormatDate: function (value, parsePattern, formtPattern) {
      return moment(value, parsePattern).format(formtPattern);
    },
    canEnroll: function (classObj) {
      return (
        classObj &&
        !classObj.is_canceled &&
        moment(classObj.start_date_at).diff(moment(), "second") >= 0
      );
    }
  }
};
