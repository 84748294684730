<template>
  <div class="lx-breadcrumb">
    <b-breadcrumb :items="items"></b-breadcrumb>
  </div>
</template>
<script>
/* 
Based on: https://bootstrap-vue.org/docs/components/breadcrumb 

Other possible values:
const items = [
  {
    text: 'Home',
    href: 'https://google.com'
  },
  {
    text: 'Posts',
    to: { name: 'home' }
  },
  {
    text: 'Another Story',
    active: true
  }
]
*/
export default {
  props: {
    items: {
      type: Array,
      default: function () {
        return [
          {
            text: "Admin",
            href: "#"
          },
          {
            text: "Manage",
            href: "#"
          },
          {
            text: "Library",
            active: true
          }
        ];
      }
    }
  }
};
</script>
<style lang="scss">
[dir="rtl"] {
  .lx-breadcrumb {
    .breadcrumb {
      .breadcrumb-item + .breadcrumb-item::before {
        padding: 0 0.5em 0 0.5em;
        float: right;
      }
    }
  }
}
.lx-breadcrumb {
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  letter-spacing: 0.2px;
  .breadcrumb {
    background: transparent;
    padding: 0;
    .breadcrumb-item {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.2px;
      color: $brand-neutral-300;
      font-family: "Roboto";
      a {
        color: $brand-neutral-300;
      }

      &.active {
        color: $brand-primary-color;
      }
    }

    .breadcrumb-item + .breadcrumb-item::before {
      color: $brand-neutral-300;
      content: "›";
      font-size: 20px;
      line-height: 14px;
    }
  }
}
</style>
