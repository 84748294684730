import Vue from "vue";
import moment from "moment";

/*
 * format - is a moment format string
 */
Vue.filter("formatDate", function (value: string, format: string) {
  if (!format) {
    moment().locale(window.navigator.language);
    format = "L";
  }
  if (value && value != "") {
    let formatDate = moment(String(value)).format(format);
    if (formatDate == "Invalid date") {
      // Fix to firefox
      formatDate = moment(String(value), format).format(format);
    }
    return formatDate;
  } else {
    return "";
  }
});
