<template>
  <input
    type="date"
    class="form-control"
    :placeholder="placeholder"
    @input="onUpdate"
    v-model="text"
  />
</template>
<script>
export default {
  props: {
    placeholder: {
      type: String,
      default: "Enter something..."
    },
    value: String
  },
  data() {
    return {
      text: this.value
    };
  },
  watch: {
    value: function (val) {
      this.text = val;
    }
  },
  mounted() {
    this.text = this.value;
  },
  methods: {
    onUpdate() {
      this.$emit("input", this.text);
    }
  }
};
</script>
<style scoped>
input {
  font-size: 16px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 18px;
  letter-spacing: 0.2px;
}
</style>
