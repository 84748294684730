import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import mixins from "./mixins/mixins";
Vue.mixin(mixins);

import Keycloak from "keycloak-js";
// Interface
import { ConfigObject } from "@/types/index";

// Vue Bootstrap
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
// vee-validate
import { ValidationProvider } from "vee-validate";
import { ValidationObserver } from "vee-validate";
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

// Filters
import "./core/filter";

Vue.config.productionTip = false;

const requireComponent = require.context(
  // The relative path of the components folder
  "./components/wrappers",
  // Whether or not to look in subfolders
  false
  // The regular expression used to match base component filenames
  ///Base[A-Z]\w+\.(vue|js)$/
);

requireComponent.keys().forEach((fileName) => {
  // Get component config
  const componentConfig = requireComponent(fileName);
  const componentName = fileName
    ?.split("/")
    ?.pop()
    ?.replace(/\.\w+$/, "")
    .toLowerCase();
  //Register component globally
  Vue.component(
    `f2f-${componentName}`,
    // Look for the component options on `.default`, which will
    // exist if the component was exported with `export default`,
    // otherwise fall back to module's root.
    componentConfig.default || componentConfig
  );
});

const keyCloakTokenParam = {
  interval: 10000, // in milliseconds - interval to check the validity of token
  nearByTime: 60 // in seconds - It is use to calculate if it will be expire in next 60 seconds
};
fetch("/config/all.json").then((response: Response) => {
  response.json().then((config: ConfigObject) => {
    Vue.prototype.$keycloak = Keycloak(config.SSO);
    Vue.prototype.$keycloak
      .init({
        onLoad: "login-required",
        checkLoginIframe: false
      })
      .then((authenticated: boolean) => {
        if (authenticated) {
          const app = new Vue({
            router,
            store,
            render: (h) => h(App)
          }).$mount("#app");
          app.$store.commit("SET_CONFIG", config);

          setInterval(() => {
            Vue.prototype.$keycloak
              .updateToken(keyCloakTokenParam.nearByTime)
              .then((refreshed: boolean) => {
                console.info(
                  "Keycloak service",
                  refreshed
                    ? "Token is still valid and not refreshed"
                    : "Token is expired and has been refreshed"
                );
              });
          }, keyCloakTokenParam.interval);
        } else {
          console.info("INIT - KC user not authenticated");
        }
      })
      .catch((err: string) => {
        console.error("ERROR Connecting to Keycloak service", err);
      });
  });
});
