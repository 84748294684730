<template>
  <b-button
    class="f2f-button"
    :variant="variant"
    :pill="pill"
    :disabled="disabled"
    :class="className"
    :size="size"
    :block="block"
    @click="$emit('click')"
    ><span v-if="text">{{ text }}</span>
    <slot></slot>
  </b-button>
</template>

<script>
export default {
  props: {
    text: {
      type: String
    },
    variant: {
      type: String,
      default: "primary",
      validator: (value) => {
        // The value must match one of these strings
        return (
          [
            "primary",
            "outline-primary",
            "info",
            "outline-info",
            "grey",
            "warning",
            "outline-warning",
            "link",
            "success"
          ].indexOf(value) !== -1
        );
      }
    },
    pill: {
      type: Boolean,
      default: false
    },
    block: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    className: {
      type: String,
      default: null
    },
    size: {
      type: String,
      default: "sm",
      validator: (value) => {
        return ["sm", "lg", "default"].indexOf(value) !== -1;
      }
    }
  }
};
</script>
<style lang="scss">
@import "@/scss/wrapper/_button.scss";
</style>
