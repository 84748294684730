<template>
  <b-alert :variant="variant" :show="display" style="padding: 0px">
    <div class="alert-box">
      <div>
        <img src="@/assets/wrappers/checkmark.svg" />
        {{ message }}
      </div>
      <img src="@/assets/wrappers/cross.svg" @click="$emit('hide')" />
    </div>
  </b-alert>
</template>
<script>
export default {
  props: {
    message: {
      type: String,
      default: "..."
    },
    display: {
      type: Boolean,
      default: false
    },
    variant: {
      type: String,
      default: "info"
    }
  }
};
</script>
<style lang="scss" scoped>
.alert {
  .alert-box {
    background: #333333;
    border-bottom: 5px solid #00c781;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.1px;
    color: #ffffff;
    display: flex;
    padding: 20px 10%;
    justify-content: space-between;
    align-items: center;

    img {
      margin-right: 6px;
      cursor: pointer;
    }
  }
}
</style>
