













import Vue from "vue";
export default Vue.extend({
  props: {
    placeholder: {
      type: String,
      default: "Enter something..."
    },
    type: {
      type: String,
      default: "text"
    },
    min: {
      type: String
    },
    max: {
      type: String
    },

    value: String
  },
  watch: {
    value: function (val) {
      this.text = val;
    }
  },
  data() {
    return {
      text: this.value
    };
  },
  mounted() {
    this.text = this.value;
  },
  methods: {
    onUpdate() {
      this.$emit("input", this.text);
    },
    onBlur() {
      if (this.type == "number") {
        if (+this.text < +this.min) {
          this.text = this.min;
          this.onUpdate();
        }
        if (+this.text > +this.max) {
          this.text = this.max;
          this.onUpdate();
        }
      }
    }
  }
});
