<template>
  <div class="accordian">
    <div class="header">
      <div class="header-row">
        <div class="icons">
          <img
            v-if="show"
            src="@/assets/wrappers/collapse.svg"
            @click="toggle"
          />
          <img
            v-if="!show"
            src="@/assets/wrappers/elapse.svg"
            @click="toggle"
          />
        </div>
        <div class="header-content">
          <slot name="header"></slot>
        </div>
      </div>
    </div>
    <b-collapse v-model="show">
      <slot name="content"></slot>
    </b-collapse>
  </div>
</template>

<script>
export default {
  props: {
    display: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      show: false
    };
  },
  mounted() {
    this.show = this.display;
  },
  methods: {
    toggle() {
      this.show = !this.show;
      this.$emit("toggle", this.show);
    }
  }
};
</script>
<style lang="scss" scoped>
.accordian {
  text-align: center;
  background: #ffffff;
  border: 1px solid #b2b2b2;
  box-sizing: border-box;
  margin-bottom: 20px;

  .header {
    padding: 1rem 1.5rem;
  }

  .header-row {
    display: flex;
    justify-content: flex-start;
  }

  .icons {
    img {
      cursor: pointer;
      width: 0.75rem;
      height: 0.75rem;
      margin-right: 0.5rem;
    }
  }

  .header-content {
    width: 100%;
  }
}
</style>
