<template>
  <div class="f2f-pagination">
    <p class="f2f-pagination__text">Rows per page:</p>
    <div class="f2f-pagination__page-count">
      <f2f-select :options="options" :value="pageSize" @input="onChange" />
    </div>
    <p class="f2f-pagination__page-info">{{ pageInfo }}</p>
    <div class="f2f-pagination__cta">
      <img
        :src="
          !previous
            ? require('@/assets/images/light-back.svg')
            : require('@/assets/images/dark-back.svg')
        "
        class="mr-4"
        :disabled="!previous"
        @click="onPrevious"
      />
      <img
        :src="
          !next
            ? require('@/assets/images/light-next.svg')
            : require('@/assets/images/dark-next.svg')
        "
        :disabled="!next"
        alt="next"
        v-if="totalRows"
        @click="onNext"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    totalRows: {
      type: Number,
      default: 0
    },
    value: String,
    previous: {
      type: String,
      required: false,
      default: ""
    },
    next: {
      type: String,
      required: false,
      default: ""
    },
    mutationName: {
      type: String
    },
    currentPage: Number,
    totalPages: Number,
    pageSize: Number
  },
  data() {
    return {
      perPage: 0,
      cPage: 1,
      options: [
        { value: "10", text: "10" },
        { value: "20", text: "20" },
        { value: "50", text: "50" }
      ]
    };
  },
  watch: {
    value: function (val) {
      this.cPage = val || 1;
    },
    perPage(newVal) {
      this.$emit("perPageUpdate", parseInt(newVal));
      this.cPage = 1;
      this.onUpdate();
    }
  },
  computed: {
    pageInfo() {
      if (this.currentPage) {
        return `${this.currentPage} of ${this.totalPages}`;
      } else {
        const start = (this.cPage - 1) * this.perPage + 1;
        const end = Math.min(this.cPage * this.perPage, this.totalRows);
        return `${start}-${end} of ${this.totalRows}`;
      }
    },

    disableNext() {
      if (Math.ceil(this.totalRows / this.perPage) == this.cPage) {
        return true;
      } else {
        return false;
      }
    }
  },
  mounted() {
    this.cPage = this.value || 1;
  },
  methods: {
    onPrevious() {
      if (this.previous) {
        this.onNextPreviousCalls(this.previous);
      } else if (this.cPage > 1) {
        this.cPage = this.cPage - 1;
        this.onUpdate();
      }
    },
    onNext() {
      if (this.next) {
        this.onNextPreviousCalls(this.next);
      } else {
        const totalPage = Math.ceil(this.totalRows / this.perPage);
        if (this.cPage < totalPage) {
          this.cPage = this.cPage + 1;
          this.onUpdate();
        }
      }
    },
    onUpdate() {
      this.$emit("page", {
        cPage: this.cPage,
        perPage: this.perPage
      });
    },
    onNextPreviousCalls(url) {
      this.$store
        .dispatch("nextPreviousCall", {
          url: url,
          mutationName: this.mutationName
        })
        .then(() => {
          this.$emit("nextPreviousCall");
        });
    },
    onChange(count) {
      this.perPage = parseInt(count);
    }
  }
};
</script>

<style lang="scss" scoped>
.f2f-pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  p,
  .f2f-pagination__page-count select {
    font-family: Roboto;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.1px;
    color: #757575;
    margin-bottom: 0;
  }
  .f2f-pagination__page-count {
    margin-left: 15px;
    .b-form-select {
      border: none;
      background: #fff url("~@/assets/images/virtual-classes/chevron-down.svg")
        right 0.75rem center/8px 10px no-repeat;
    }
  }
  .f2f-pagination__page-info {
    margin: 0 30px;
  }
  .f2f-pagination__cta {
    display: flex;
    align-items: center;
    cursor: pointer;
    img[disabled="disabled"] {
      cursor: not-allowed;
    }
  }
}
</style>
