<template>
  <b-form-group :label="label">
    <b-form-radio
      class="radio"
      v-model="selected"
      name="radios"
      :value="option.value"
      v-for="option in options"
      v-bind:key="option.value"
      @change="onUpdate"
      >{{ option.text }}</b-form-radio
    >
  </b-form-group>
</template>

<script>
export default {
  data() {
    return {
      selected: ""
    };
  },
  watch: {
    value: function (val) {
      this.selected = val;
    }
  },
  mounted() {
    this.selected = this.value;
  },
  props: {
    label: {
      type: String,
      default: ""
    },
    options: {
      type: Array,
      default: () => ({})
    },
    value: String
  },
  methods: {
    onUpdate() {
      this.$emit("input", this.selected);
    }
  }
};
</script>

<style lang="scss" scoped>
.radio {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  float: left;
  align-items: flex-end;
  letter-spacing: 0.1px;
  color: #25282b;
  margin-right: 50px;

  .custom-control-label {
    margin-left: 200px;
  }
}
</style>
